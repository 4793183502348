import PublicProfileClassic from 'components/publicProfile/classic/PublicProfileClassic';
import PublicProfilePdfComponent from 'components/publicProfile/pdf/PublicProfilePdfComponent';
import PublicProfileTimelineComponent from 'components/publicProfile/timeline/PublicProfileTimelineComponent';
import HeadMetadata from 'components/shared/HeadMetadata';
import { useI18n } from 'hooks/useI18n';
import {
  ManfrediteConfigurationPublicProfileViewsType,
  PublicManfredite
} from 'interfaces/public-manfredite.interface';
import { useRouter } from 'next/router';
import { Dispatch, SetStateAction } from 'react';
import UIPublicProfileService from 'services/ui/publicprofile/publicprofile.service';

type ProfilePreviewComponentProps = {
  type?: ManfrediteConfigurationPublicProfileViewsType;
  publicManfredite: PublicManfredite;
  setHasLinkedInQuery?: Dispatch<SetStateAction<boolean>>;
};

const ProfilePreviewComponent: React.FC<ProfilePreviewComponentProps> = ({
  publicManfredite,
  setHasLinkedInQuery,
  type = 'timeline'
}) => {
  const { t } = useI18n('publicProfile');
  const { locale } = useRouter();

  const uiPublicManfredite = UIPublicProfileService.getManfrediteUIData(
    publicManfredite,
    locale
  );

  const { title, desc, avatarUrl } =
    UIPublicProfileService.getManfrediteProfileMetadataInfo(
      uiPublicManfredite,
      t
    );

  return (
    <>
      <HeadMetadata title={title} desc={desc} imgUrl={avatarUrl} />

      {(!type || type === 'timeline') && (
        <PublicProfileTimelineComponent publicManfredite={uiPublicManfredite} />
      )}

      {type === 'classic' && (
        <PublicProfileClassic
          manfredite={uiPublicManfredite}
          setHasLinkedInQuery={setHasLinkedInQuery}
        />
      )}

      {type === 'pdf' && (
        <PublicProfilePdfComponent publicManfredite={uiPublicManfredite} />
      )}
    </>
  );
};

export default ProfilePreviewComponent;
